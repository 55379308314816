import { css } from '@/styled-system/css';

export const styledToolsSection = css.raw({
  '& a': {
    color: 'brand.perwinkle.300',
    textDecoration: 'underline',
    _hover: {
      textDecoration: 'none',
    },
  },
});
