export const HTML_ELEMENTS_ATTRS: any = {
  '*': [
    'accesskey',
    'autocapitalize',
    'class',
    'contenteditable',
    'contextmenu',
    'data-*',
    'dir',
    'draggable',
    'hidden',
    'id',
    'itemprop',
    'lang',
    'role',
    'slot',
    'spellcheck',
    'style',
    'tabindex',
    'title',
    'translate',
  ],
  a: [
    'download',
    'href',
    'hreflang',
    'media',
    'ping',
    'referrerpolicy',
    'rel',
    'shape',
    'target',
  ],
  abbr: [],
  acronym: [],
  address: [],
  applet: ['align', 'alt', 'code', 'codebase'],
  area: [
    'alt',
    'coords',
    'download',
    'href',
    'hreflang',
    'media',
    'ping',
    'referrerpolicy',
    'rel',
    'shape',
    'target',
  ],
  article: [],
  aside: [],
  audio: [
    'autoplay',
    'buffered',
    'controls',
    'crossorigin',
    'loop',
    'muted',
    'preload',
    'src',
  ],
  b: [],
  base: ['href', 'target'],
  basefont: ['color'],
  bdi: [],
  bdo: [],
  bgsound: ['loop'],
  big: [],
  blink: [],
  blockquote: ['cite'],
  body: ['background', 'bgcolor'],
  br: [],
  button: [
    'autofocus',
    'disabled',
    'form',
    'formaction',
    'formenctype',
    'formmethod',
    'formnovalidate',
    'formtarget',
    'name',
    'type',
    'value',
  ],
  canvas: ['height', 'width'],
  caption: ['align'],
  center: [],
  cite: [],
  code: [],
  col: ['align', 'bgcolor', 'span'],
  colgroup: ['align', 'bgcolor', 'span'],
  command: ['checked', 'disabled', 'icon', 'radiogroup', 'type'],
  content: [],
  contenteditable: ['enterkeyhint', 'inputmode'],
  data: ['value'],
  datalist: [],
  dd: [],
  del: ['cite', 'datetime'],
  details: ['open'],
  dfn: [],
  dialog: ['open'],
  dir: [],
  div: [],
  dl: [],
  dt: [],
  em: [],
  embed: ['height', 'src', 'type', 'width'],
  fieldset: ['disabled', 'form', 'name'],
  figcaption: [],
  figure: [],
  font: ['color'],
  footer: [],
  form: [
    'accept',
    'accept-charset',
    'action',
    'autocomplete',
    'enctype',
    'method',
    'name',
    'novalidate',
    'target',
  ],
  frame: [],
  frameset: [],
  h1: [],
  h2: [],
  h3: [],
  h4: [],
  h5: [],
  h6: [],
  head: [],
  header: [],
  hgroup: [],
  hr: ['align', 'color'],
  html: ['manifest'],
  i: [],
  iframe: [
    'align',
    'allow',
    'csp',
    'height',
    'importance',
    'loading',
    'name',
    'referrerpolicy',
    'sandbox',
    'src',
    'srcdoc',
    'width',
  ],
  image: [],
  img: [
    'align',
    'alt',
    'border',
    'crossorigin',
    'decoding',
    'height',
    'importance',
    'intrinsicsize',
    'ismap',
    'loading',
    'referrerpolicy',
    'sizes',
    'src',
    'srcset',
    'usemap',
    'width',
  ],
  input: [
    'accept',
    'alt',
    'autocomplete',
    'autofocus',
    'capture',
    'checked',
    'dirname',
    'disabled',
    'form',
    'formaction',
    'formenctype',
    'formmethod',
    'formnovalidate',
    'formtarget',
    'height',
    'list',
    'max',
    'maxlength',
    'minlength',
    'min',
    'multiple',
    'name',
    'pattern',
    'placeholder',
    'readonly',
    'required',
    'size',
    'src',
    'step',
    'type',
    'usemap',
    'value',
    'width',
  ],
  ins: ['cite', 'datetime'],
  kbd: [],
  keygen: ['autofocus', 'challenge', 'disabled', 'form', 'keytype', 'name'],
  label: ['for', 'form'],
  legend: [],
  li: ['value'],
  link: [
    'crossorigin',
    'href',
    'hreflang',
    'importance',
    'integrity',
    'media',
    'referrerpolicy',
    'rel',
    'sizes',
    'type',
  ],
  main: [],
  map: ['name'],
  mark: [],
  marquee: ['bgcolor', 'loop'],
  math: [],
  menu: ['type'],
  menuitem: [],
  meta: ['charset', 'content', 'http-equiv', 'name'],
  meter: ['form', 'high', 'low', 'max', 'min', 'optimum', 'value'],
  nav: [],
  nobr: [],
  noembed: [],
  noframes: [],
  noscript: [],
  object: [
    'border',
    'data',
    'form',
    'height',
    'name',
    'type',
    'usemap',
    'width',
  ],
  ol: ['reversed', 'start'],
  optgroup: ['disabled', 'label'],
  option: ['disabled', 'label', 'selected', 'value'],
  output: ['for', 'form', 'name'],
  p: [],
  param: ['name', 'value'],
  picture: [],
  plaintext: [],
  portal: [],
  pre: [],
  progress: ['form', 'max', 'value'],
  q: ['cite'],
  rb: [],
  rp: [],
  rt: [],
  rtc: [],
  ruby: [],
  s: [],
  samp: [],
  script: [
    'async',
    'charset',
    'crossorigin',
    'defer',
    'importance',
    'integrity',
    'language',
    'referrerpolicy',
    'src',
    'type',
  ],
  section: [],
  select: [
    'autocomplete',
    'autofocus',
    'disabled',
    'form',
    'multiple',
    'name',
    'required',
    'size',
  ],
  shadow: [],
  slot: [],
  small: [],
  source: ['media', 'sizes', 'src', 'srcset', 'type'],
  spacer: [],
  span: [],
  strike: [],
  strong: [],
  style: ['media', 'scoped', 'type'],
  sub: [],
  summary: [],
  sup: [],
  svg: [],
  table: ['align', 'background', 'bgcolor', 'border', 'summary'],
  tbody: ['align', 'bgcolor'],
  td: ['align', 'background', 'bgcolor', 'colspan', 'headers', 'rowspan'],
  template: [],
  textarea: [
    'autocomplete',
    'autofocus',
    'cols',
    'dirname',
    'disabled',
    'enterkeyhint',
    'form',
    'inputmode',
    'maxlength',
    'minlength',
    'name',
    'placeholder',
    'readonly',
    'required',
    'rows',
    'wrap',
  ],
  tfoot: ['align', 'bgcolor'],
  th: [
    'align',
    'background',
    'bgcolor',
    'colspan',
    'headers',
    'rowspan',
    'scope',
  ],
  thead: ['align'],
  time: ['datetime'],
  title: [],
  tr: ['align', 'bgcolor'],
  track: ['default', 'kind', 'label', 'src', 'srclang'],
  tt: [],
  u: [],
  ul: [],
  var: [],
  video: [
    'autoplay',
    'buffered',
    'controls',
    'crossorigin',
    'height',
    'loop',
    'muted',
    'poster',
    'preload',
    'src',
    'width',
  ],
  wbr: [],
  xmp: [],
};
