//utils/img/imgixLoader.ts
import { isValidURL } from '@/utils/string/isValidURL';

export default function imgixLoader({
  src,
  quality,
  width,
}: {
  src: string;
  quality: number;
  width: number | string;
}) {
  if (src.includes('imgix.net')) {
    return getImgixUrl({ src, quality, width });
  }

  return src;
}

export function getImgixUrl({
  src,
  quality = 25,
  width,
}: {
  src: string;
  quality?: number;
  width: number | string;
}) {
  if (!src) {
    return '';
  }

  try {
    if (isValidURL(src)) {
      const url = new URL(src);
      const params = url.searchParams;

      params.set('auto', params.getAll('auto').join(',') || 'compress,format');
      params.set('fit', params.get('fit') || 'crop');
      params.set('crop', params.get('crop') || 'edges');
      params.set('w', params.get('w') || width.toString());
      params.set('q', quality.toString());

      return url.href;
    }

    return src;
  } catch (err) {
    console.error(err);
    return '';
  }
}
