import NextImage from 'next/image';
import { PlaceholderValue } from 'next/dist/shared/lib/get-img-props';
export default function Image({
  alt = '',
  height = 300,
  loading = 'lazy',
  placeholder = 'blur',
  priority = false,
  quality = 25,
  role = '',
  src = '',
  style = null,
  width = 300,
  ...props
}: {
  alt?: string;
  height?: number;
  loading?: 'lazy' | 'eager';
  placeholder?: PlaceholderValue;
  priority?: boolean;
  quality?: number;
  role?: string;
  src: string;
  style?: object;
  width?: number;
}) {
  const isCDNImage = src.includes('imgix.net');
  if (isCDNImage) {
    let usePlaceHolder = true;
    if (height <= 40 && width <= 40) {
      usePlaceHolder = false;
    }
    return <NextImage alt={alt} blurDataURL={usePlaceHolder ? `${src}&fm=blurhash` : null} height={height} loading={priority ? 'eager' : loading} placeholder={usePlaceHolder ? placeholder : 'empty'} priority={priority} quality={quality} role={role} src={src} style={style} width={width} {...props} />;
  }
  return <NextImage alt={alt} height={height} placeholder="empty" priority={priority} quality={quality} role={role} src={src} style={style} width={width} {...props} />;
}