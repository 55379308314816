import { ReactNode, useEffect, useState } from 'react';
import { getCookie } from '@/utils/cookie/getCookie';
export default function Guest({
  children
}: {
  children: ReactNode | null;
}) {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    const authCookie = getCookie('userLoginState');
    setAuthenticated(authCookie === 'logged-in');
  }, []);
  return authenticated ? null : children;
}