import { css } from '@/styled-system/css';
import { ReactNode } from 'react';
import { getPropsFromElement } from '@/utils/parser/htmlAttributes';
export function List({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  const attrs = getPropsFromElement('ul', props);
  return <ul className={css({
    listStyleType: 'none',
    ...props
  })} {...attrs}>
      {children}
    </ul>;
}
export function UnorderedList({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  const attrs = getPropsFromElement('ul', props);
  return <ul className={css({
    listStyleType: 'initial',
    ...props
  })} {...attrs}>
      {children}
    </ul>;
}
export function OrderedList({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  const attrs = getPropsFromElement('ol', props);
  return <ol className={css({
    listStyleType: 'decimal',
    ...props
  })} {...attrs}>
      {children}
    </ol>;
}
export function ListItem({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  const attrs = getPropsFromElement('li', props);
  return <li className={css(props)} {...attrs}>
      {children}
    </li>;
}