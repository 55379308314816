import { ReactNode } from 'react';
import NextLink from 'next/link';
import { css } from '@/styled-system/css';
import { getPropsFromElement } from '@/utils/parser/htmlAttributes';
export function Link({
  children,
  prefetch = false,
  ...props
}: {
  children: ReactNode;
  prefetch?: boolean;
  [props: string]: any;
}) {
  const attrs = {
    ...getPropsFromElement('a', props),
    prefetch
  };
  return <NextLink className={css(props)} {...attrs} href={attrs.href || '#'}>
      {children}
    </NextLink>;
}