import { STATES } from '@/data/states';

const AFTERALL_DICT = {
  AZ: {
    href: 'https://www.afterall.com/locations/arizona',
  },
  CA: {
    href: 'https://www.afterall.com/locations/california',
  },
  CO: {
    href: 'https://www.afterall.com/locations/colorado',
  },
  FL: {
    href: 'https://www.afterall.com/locations/florida',
  },
  GA: {
    href: 'https://www.afterall.com/locations/georgia',
  },
  ID: {
    href: 'https://www.afterall.com/locations/idaho',
  },
  KY: {
    href: 'https://www.afterall.com/locations/kentucky',
  },
  MD: {
    href: 'https://www.afterall.com/locations/maryland',
  },
  MA: {
    href: 'https://www.afterall.com/locations/massachusetts',
  },
  MN: {
    href: 'https://www.afterall.com/locations/minnesota',
  },
  NV: {
    href: 'https://www.afterall.com/locations/nevada',
  },
  NJ: {
    href: 'https://www.afterall.com/locations/new-jersey',
  },
  NM: {
    href: 'https://www.afterall.com/locations/new-mexico',
  },
  NY: {
    href: 'https://www.afterall.com/locations/new-york',
  },
  NC: {
    href: 'https://www.afterall.com/locations/north-carolina',
  },
  OR: {
    href: 'https://www.afterall.com/locations/oregon',
  },
  SC: {
    href: 'https://www.afterall.com/locations/south-carolina',
  },
  TN: {
    href: 'https://www.afterall.com/locations/tennessee',
  },
  VA: {
    href: 'https://www.afterall.com/locations/virginia',
  },
  WA: {
    href: 'https://www.afterall.com/locations/washington',
  },
  WI: {
    href: 'https://www.afterall.com/locations/wisconsin',
  },
};

const IGNORED_STATES = ['DC', 'HI', 'VT'];

const AFTERALL_DIRECTORY = new Map(Object.entries(AFTERALL_DICT));

const DIRECTORY_DICT: any = {};

const states = new Map(
  [...STATES].filter(([k]) => !IGNORED_STATES.includes(k))
);

for (const [key, value] of states) {
  DIRECTORY_DICT[key] = { ...value, href: `/funeral-directory/${value.value}` };

  if (AFTERALL_DIRECTORY.get(key)) {
    DIRECTORY_DICT[key] = {
      ...DIRECTORY_DICT[key],
      ...AFTERALL_DIRECTORY.get(key),
      external: true,
    };
  }
}

export const FUNERAL_DIRECTORY = new Map(Object.entries(DIRECTORY_DICT));
