import { css } from '@/styled-system/css';
import { ReactNode } from 'react';
import { Link } from '@/components/ui/link/Link';
import { Box } from '@/styled-system/jsx';
import { getPropsFromElement } from '@/utils/parser/htmlAttributes';
import { styledButton } from '@/components/ui/button/Button.styles';
export function Button({
  as = 'button',
  children,
  prefetch = false,
  ...props
}: {
  as?: string;
  children: ReactNode;
  prefetch?: boolean;
  [props: string]: any;
}) {
  const attrs = getPropsFromElement(as, props);
  const styles = css(props);
  const cvaStyles = styledButton({
    visual: 'ghost',
    size: 'lg',
    ...props
  });
  if (as === 'a') {
    return <Box className={styles}>
        <button className={cvaStyles}>
          <Link prefetch={prefetch} textDecoration="none" {...attrs}>
            {children}
          </Link>
        </button>
      </Box>;
  }
  return <button className={cvaStyles} {...attrs}>
      {children}
    </button>;
}