import { INavItem } from '@/types/navitem';
import { FUNERAL_DIRECTORY } from '@/data/funeralDirectory';
import { chunkArray } from '@/utils/array/chunk';

const FUNERAL_DIRECTORY_NAV_ITEMS: any[] = chunkArray(
  Array.from(FUNERAL_DIRECTORY.values()).map((item: any) => ({
    ...item,
    href: item.external
      ? `${item.href}/?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navdd`
      : item.href,
  })),
  2
);

const NAV_ITEMS_HEADER: Array<INavItem> = [
  {
    label: 'End-of-life planning',
    subLabel:
      "We'll help you get your affairs in order and make sure nothing is left out. Now you can focus on leaving a legacy instead of a mess.",
    iconUrl: 'https://joincake.imgix.net/illustration-grandma-3.png',
    iconAltTag: 'Woman holding an older woman',
    children: [
      {
        label: 'Finance',
        children: [
          {
            label: 'Wills & Estate Planning',
            href: '/blog/category/wills-and-estate-planning/',
          },
          {
            label: 'Life Insurance',
            href: '/blog/category/life-insurance/',
          },
          {
            label: 'Power of attorney',
            href: '/blog/category/power-of-attorney/',
          },
        ],
      },
      {
        label: 'Health',
        children: [
          {
            label: 'Advance directives & living wills',
            href: '/blog/category/advance-directives-and-living-wills/',
          },
          {
            label: 'Hospice & palliative care',
            href: '/blog/category/hospice-and-palliative-care/',
          },
          {
            label: "Alzheimer's & dementia",
            href: '/blog/category/alzheimers-and-dementia/',
          },
          {
            label: 'Serious & terminal illness',
            href: '/blog/category/serious-and-terminal-illness/',
          },
          {
            label: 'Eldercare',
            href: '/blog/category/eldercare/',
          },
          {
            label: 'Death & dying',
            href: '/blog/category/death-and-dying/',
          },
        ],
      },
      {
        label: 'Legacy',
        children: [
          {
            label: 'Legacy projects',
            href: '/blog/category/legacy-projects/',
          },
          {
            label: 'Digital afterlife',
            href: '/blog/category/digital-afterlife/',
          },
          {
            label: 'Genealogy & family history',
            href: '/blog/category/genealogy-and-family-history/',
          },
        ],
      },
      {
        label: 'Funeral',
        children: [
          {
            label: 'Funeral Preplanning',
            href: 'https://www.afterall.com/funeral-planning-tailor-made-for-you.html?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navigationeol',
          },
          {
            label: 'Funerals & memorial services',
            href: '/blog/category/funerals-and-memorial-services/',
          },
          {
            label: 'Celebrations of life',
            href: '/blog/category/celebrations-of-life/',
          },
          {
            label: 'Cremation',
            href: '/blog/category/cremation/',
          },
          {
            label: 'Burial',
            href: '/blog/category/burial/',
          },
          {
            label: 'Eco-friendly funerals',
            href: '/blog/category/eco-friendly-funerals/',
          },
          {
            label: 'Cemeteries & graveyards',
            href: '/blog/category/cemeteries-and-graveyards/',
          },
          {
            label: 'Headstones & gravestones',
            href: '/blog/category/headstones-and-gravestones/',
          },
          {
            label: 'Funeral home directory',
            href: '/funeral-directory/',
          },
        ],
      },
      {
        label: 'Relationships',
        children: [
          {
            label: 'How to get loved one to plan',
            href: '/blog/category/how-to-get-loved-one-to-plan/',
          },
          {
            label: 'How to share your wishes',
            href: '/blog/category/how-to-share-your-wishes/',
          },
        ],
      },
    ],
  },
  {
    label: 'Loss & grief',
    subLabel:
      'Loss is hard. Our expert guidance can make your life a little easier during this time. Find out what to do and discover resources to help you cope.',
    iconUrl: 'https://joincake.imgix.net/illustration-women.png',
    iconAltTag: 'Woman consoling her friend under a tree',
    children: [
      {
        label: 'Types of Loss',
        children: [
          {
            label: 'Mother',
            href: '/blog/category/loss-of-mother/',
          },
          {
            label: 'Father',
            href: '/blog/category/loss-of-father/',
          },
          {
            label: 'Spouse or partner',
            href: '/blog/category/loss-of-spouse-or-partner/',
          },
          {
            label: 'Child',
            href: '/blog/category/loss-of-child',
          },
          {
            label: 'Miscarriage & stillbirth',
            href: '/blog/category/miscarriage-and-stillbirth/',
          },
          {
            label: 'Grandparent',
            href: '/blog/category/loss-of-grandparent/',
          },
          {
            label: 'Sibling',
            href: '/blog/category/loss-of-sibling/',
          },
          {
            label: 'Friend',
            href: '/blog/category/loss-of-friend/',
          },
          {
            label: 'Pet',
            href: '/blog/category/loss-of-pet/',
          },
        ],
      },
      {
        label: 'Grief Support',
        children: [
          {
            label: 'Support groups & counseling',
            href: '/blog/category/grief-support-groups-and-counseling/',
          },
          {
            label: 'Quotes, prayers & more',
            href: '/blog/category/grief-quotes-prayers-and-more/',
          },
          {
            label: 'Coping with grief',
            href: '/blog/category/coping-with-grief/',
          },
          {
            label: 'Grief & relationships',
            href: '/blog/category/grief-and-relationships',
          },
        ],
      },
      {
        label: 'Sympathy & Condolences',
        children: [
          {
            label: 'Memorial store',
            href: 'https://www.afterall.com/store/?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navigationlg',
          },
          {
            label: 'Funeral flowers',
            href: '/blog/category/funeral-flowers/',
          },
          {
            label: 'Sympathy gifts',
            href: '/blog/category/sympathy-gifts/',
          },
          {
            label: 'Condolences & what to say',
            href: '/blog/category/condolences-and-what-to-say',
          },
          {
            label: 'Funeral etiquette',
            href: '/blog/category/funeral-etiquette/',
          },
        ],
      },
      {
        label: 'Estate management',
        children: [
          {
            label: 'Wills & probate',
            href: '/blog/category/wills-and-probate/',
          },
          {
            label: 'Closing & managing accounts',
            href: '/blog/category/closing-and-managing-accounts/',
          },
          {
            label: 'Death certificate',
            href: '/blog/category/death-certificates/',
          },
          {
            label: 'Home & belongings',
            href: '/blog/category/home-and-belongings/',
          },
        ],
      },
      {
        label: 'Funeral planning',
        children: [
          {
            label: 'Funeral Preplanning',
            href: 'https://www.afterall.com/funeral-planning-tailor-made-for-you.html?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navigationlg',
          },
          {
            label: 'Funerals & memorial services',
            href: '/blog/category/funerals-and-memorial-services/',
          },
          {
            label: 'Celebrations of life',
            href: '/blog/category/celebrations-of-life/',
          },
          {
            label: 'Eulogies',
            href: '/blog/category/eulogies/',
          },
          {
            label: 'Obituaries',
            href: '/blog/category/obituaries/',
          },
          {
            label: 'Death announcements',
            href: '/blog/category/death-announcements/',
          },
          {
            label: 'Headstones & gravestones',
            href: '/blog/category/headstones-and-gravestones/',
          },
          {
            label: 'Funeral home directory',
            href: '/funeral-directory/',
          },
        ],
      },
      {
        label: 'Remembrance',
        children: [
          {
            label: 'Memorials',
            href: '/blog/category/memorials/',
          },
          {
            label: 'Memorial donations',
            href: '/blog/category/memorial-donations/',
          },
          {
            label: 'Keepsakes',
            href: '/blog/category/remembrance-keepsakes/',
          },
          {
            label: 'Death anniversary',
            href: '/blog/category/death-anniversary/',
          },
        ],
      },
    ],
  },
  {
    label: 'Explore mortality',
    subLabel:
      'We believe reflecting on our mortality can help us lead more meaningful lives.',
    iconUrl: 'https://joincake.imgix.net/illustration-explore.png',
    iconAltTag: 'Contemplative woman taking a walk in a field',
    children: [
      {
        label: 'Media & Art',
        children: [
          {
            label: 'Books',
            href: '/blog/category/books-about-death/',
          },
          {
            label: 'Movies',
            href: '/blog/category/movies-about-death/',
          },
          {
            label: 'Quotes',
            href: '/blog/category/quotes-about-death/',
          },
          {
            label: 'Songs',
            href: '/blog/category/songs-about-death/',
          },
          {
            label: 'Poems',
            href: '/blog/category/poems-about-death/',
          },
          {
            label: 'How to talk about death',
            href: '/blog/category/how-to-talk-about-death/',
          },
          {
            label: 'Symbols of death',
            href: '/blog/category/symbols-of-death/',
          },
        ],
      },
      {
        label: 'Find Meaning',
        children: [
          {
            label: 'Bucket list',
            href: '/blog/category/bucket-list/',
          },
          {
            label: 'Gratitude',
            href: '/blog/category/gratitude/',
          },
          {
            label: 'Relationships',
            href: '/blog/category/relationships/',
          },
        ],
      },
      {
        label: 'Religion & Culture',
        children: [
          {
            label: 'Buddhist',
            href: '/blog/category/buddhist-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Christian',
            href: '/blog/category/christian-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Hindu',
            href: '/blog/category/hindu-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Jewish',
            href: '/blog/category/jewish-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Muslim',
            href: '/blog/category/muslim-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Chinese',
            href: '/blog/category/chinese-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Greek',
            href: '/blog/category/greek-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Irish',
            href: '/blog/category/irish-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Italian',
            href: '/blog/category/italian-death-traditions-rituals-and-beliefs/',
          },
          {
            label: 'Native American',
            href: '/blog/category/native-american-death-traditions-rituals-and-beliefs/',
          },
        ],
      },
    ],
  },
  {
    label: 'Find a funeral home',
    // href: '/funeral-directory',
    subHeading: 'States',
    subLabel:
      "We understand that with so many options, it can be difficult to know where to start. We've complied a list of trusted, local funeral homes that can help you to understand your options.",
    iconUrl: 'https://joincake.imgix.net/undraw_wishes.svg',
    iconAltTag: 'Contemplative woman taking a walk in a field',
    cta: {
      href: '/funeral-directory',
      label: 'Find a funeral home',
    },
    children: [
      {
        label: 'States (A-M)',
        labelMobileOnly: true,
        children: [
          ...FUNERAL_DIRECTORY_NAV_ITEMS[0],
          FUNERAL_DIRECTORY_NAV_ITEMS[1][0],
        ],
      },
      {
        label: 'States (N-Z)',
        labelMobileOnly: true,
        children: FUNERAL_DIRECTORY_NAV_ITEMS[1].slice(1),
      },
    ],
    columnStyle: {
      maxHeight: 650,
    },
  },
];

export const NAV_ITEMS_HEADER_MOBILE: Array<INavItem> = [
  ...NAV_ITEMS_HEADER,
  {
    label: 'Service directory',
    href: '/directory/',
  },
  {
    label: 'Memorial Store',
    href: 'https://www.afterall.com/store/?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navigationlg',
  },
  {
    label: 'Contact Us',
    href: '/contact/',
  },
];

export const NAV_ITEMS_HEADER_DESKTOP: Array<INavItem> = [
  ...NAV_ITEMS_HEADER,
  {
    label: 'Service directory',
    href: '/directory/',
    secondary: true,
    underline: true,
    style: {
      fontSize: '13px',
    },
  },
  {
    label: 'Memorial Store',
    href: 'https://www.afterall.com/store/?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navigationlg',
    secondary: true,
    underline: true,
    style: {
      fontSize: '13px',
    },
  },
];
