import Image from '@/components/image/Image';
import { Link } from '@/components/ui/link/Link';
import { Text } from '@/components/ui/typography/Text';
import { Box, Flex, VStack } from '@/styled-system/jsx';
import { TOOLS_LIST } from '@/data/toolsList';
import { styledToolsSection } from '@/components/ui/header/ToolsSection.styles';
import { css } from '@/styled-system/css';
export const ToolsSection = () => {
  return <Box className={css(styledToolsSection)}>
      <VStack alignItems={'left'} gap={3} mb={5}>
        <Text as="h6" color={'black'} display={{
        base: 'none',
        lg: 'block'
      }} fontWeight={500} fontSize={'md'}>
          Search for a specific topic
        </Text>

        {TOOLS_LIST.map(tool => <Flex key={tool.label}>
            <Box mr={3}>
              <Link href={tool.href}>
                <Image alt="tool icon" height={44} quality={25} src={tool.iconUrl} width={44} />
              </Link>
            </Box>
            <Box lineHeight={1.25}>
              <Text as={'h3'} fontSize="md" fontWeight={'600'} mb={0}>
                <Link color="link" href={tool.href}>
                  {tool.label}
                </Link>
              </Text>
              <Text as={'p'} fontSize="sm">
                {tool.subLabel}
              </Text>
            </Box>
          </Flex>)}

        <Text color={'black'} display={{
        base: 'none',
        lg: 'block'
      }} fontSize={'sm'} m={0} mt={3}>
          <Link color="link" href={'/contact/'}>
            Contact us
          </Link>{' '}
          for additional help.
        </Text>
      </VStack>
    </Box>;
};