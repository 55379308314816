import { css } from '@/styled-system/css';
import { ReactNode } from 'react';
import { getPropsFromElement } from '@/utils/parser/htmlAttributes';
import { omit } from '@/utils/array/omit';
export function Heading({
  as = 'h1',
  children,
  ...props
}: {
  as?: string;
  children: ReactNode;
  [props: string]: any;
}) {
  const attrs = omit(getPropsFromElement(as, props), 'className');
  if (as === 'h1') {
    return <h1 className={css({
      fontFamily: 'heading',
      ...props
    })} {...attrs}>
        {children}
      </h1>;
  }
  if (as === 'h2') {
    return <h2 className={css({
      fontFamily: 'heading',
      ...props
    })} {...attrs}>
        {children}
      </h2>;
  }
  if (as === 'h3') {
    return <h3 className={css({
      fontFamily: 'heading',
      ...props
    })} {...attrs}>
        {children}
      </h3>;
  }
  if (as === 'h4') {
    return <h4 className={css({
      fontFamily: 'heading',
      ...props
    })} {...attrs}>
        {children}
      </h4>;
  }
  if (as === 'h5') {
    return <h5 className={css({
      fontFamily: 'heading',
      ...props
    })} {...attrs}>
        {children}
      </h5>;
  }
  if (as === 'h6') {
    return <h6 className={css({
      fontFamily: 'heading',
      ...props
    })} {...attrs}>
        {children}
      </h6>;
  }
}