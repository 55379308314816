import { HTML_ELEMENTS_ATTRS } from '@/data/htmlElementAttrs';
import { EVENTS as events } from '@/data/events';

export function getAttributes(elementName: string) {
  const ele = HTML_ELEMENTS_ATTRS[elementName];
  const global = [...HTML_ELEMENTS_ATTRS['*'], ...events];

  let attrList = [];

  if (ele && ele.length > 0) {
    attrList = [...ele, ...global];
  } else {
    attrList = global;
  }

  return [...attrList];
}

export function getPropsFromElement(elementName: string, props: any = {}) {
  const propKeys = Object.keys(props);
  const attrList = getAttributes(elementName);
  const propList: any = {};

  attrList.forEach((attr: string) => {
    propKeys.forEach((prop: string) => {
      const re = prop.includes('data-')
        ? new RegExp(attr)
        : new RegExp(`^${attr}$`);

      if (re.test(prop)) {
        propList[prop] = props[prop];
      }
    });
  });

  return propList;
}
