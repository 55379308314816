import { Button } from '@/components/ui/button/Button';
import { Stack } from '@/styled-system/jsx';
import Authenticated from '@/components/user/Authenticated';
import { BsSearch } from 'react-icons/bs';
export default function UserActions({
  onSearchToggle = null,
  showDashboard = true,
  showLogout = true,
  showSearch = false
}: {
  onSearchToggle?: () => void;
  showDashboard?: boolean;
  showLogout?: boolean;
  showSearch?: boolean;
}) {
  return <Authenticated>
      <Stack direction={['column', 'row']} gap={{
      sm: 5,
      lg: 1
    }}>
        {showDashboard && <Button as={'a'} fontSize={'md'} _hover={{
        bg: 'gold.500'
      }} href={'/home/'} visual={'primary'} w={{
        base: '100%',
        sm: 'auto'
      }}>
            Dashboard
          </Button>}

        {showSearch && <BsSearch aria-label={'Toggle Search'} color={'brand.periwinkle.500'} onClick={onSearchToggle} size={20} />}

        {showLogout && <Button as="a" href={'/account/logoff/'} textDecoration={{
        lg: 'underline'
      }} visual={'ghost'} w={{
        base: '100%',
        sm: 'auto'
      }} style={{
        fontSize: 13
      }}>
            Log out
          </Button>}
      </Stack>
    </Authenticated>;
}