import { List, ListItem } from '@/components/ui/list/List';
import { Link } from '@/components/ui/link/Link';
import { Text } from '@/components/ui/typography/Text';
import { Box, Stack } from '@/styled-system/jsx';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { INavItem } from '@/types/navitem';
import { useState } from 'react';
export const MobileNavItem = ({
  label,
  children,
  href
}: INavItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(!isOpen);
  return <Stack gap={0} onClick={children && onToggle}>
      <Link display={'flex'} py={1} href={href ?? '#'} justifyContent="space-between" alignItems="center" textDecoration="none">
        <Text fontWeight={600} color={'gray.600'} textDecoration={label === 'Find a service' ? 'underline' : 'none'}>
          {label}
        </Text>
        {children && (isOpen ? <BiChevronUp
      // transition={'all .25s ease-in-out'}
      transform={isOpen ? 'rotate(180deg)' : ''} size={10} /> : <BiChevronDown
      // transition={'all .25s ease-in-out'}
      transform={isOpen ? 'rotate(180deg)' : ''} size={10} />)}
      </Link>

      <Box display={isOpen ? 'block' : 'none'}>
        <Stack mt={0} pb={4} pl={4} borderStyle={'solid'} borderColor={'gray.700'} alignItems={'start'}>
          {children && children.map(child => <Box key={child.label}>
                <Text as="span" fontSize="sm" fontWeight={500}>
                  {child.label}
                </Text>

                {child.children && <List fontSize="sm">
                    {child.children.map(grandChild => <ListItem key={grandChild.label}>
                        <Link href={grandChild.href}>{grandChild.label}</Link>
                      </ListItem>)}
                  </List>}
              </Box>)}
        </Stack>
      </Box>
    </Stack>;
};