'use client';

import { Link } from '@/components/ui/link/Link';
import { Text } from '@/components/ui/typography/Text';
import { Accordion } from '@ark-ui/react';
import { Box, Flex } from '@/styled-system/jsx';
import { box } from '@/styled-system/patterns';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { NAV_ITEMS_FOOTER } from '@/data/navFooter';
import { useState } from 'react';
export default function MobileFooter() {
  const [value, setValue] = useState<string[]>([]);
  return <nav className={box({
    display: {
      base: 'block',
      md: 'none'
    }
  })}>
      {NAV_ITEMS_FOOTER.map((navItem, i: number) => <Box borderColor="gray.700" borderStyle="solid" borderBottomWidth={1} borderTopWidth={i == 0 ? 1 : 0} key={navItem.label} {...navItem}>
          <Accordion.Root multiple onValueChange={details => setValue(details.value)}>
            <Accordion.Item value={navItem.label}>
              <Accordion.ItemTrigger style={{
            alignItems: 'center',
            display: 'flex',
            paddingBlock: 'var(--spacing-2)',
            paddingInline: 'var(--spacing-4)',
            width: '100%'
          }}>
                <Text>{navItem.label}</Text>

                <Box ml="auto">
                  <Accordion.ItemIndicator>
                    {value.includes(navItem.label) ? <BiChevronUp /> : <BiChevronDown />}
                  </Accordion.ItemIndicator>
                </Box>
              </Accordion.ItemTrigger>
              <Accordion.ItemContent>
                <Box px={4} pb={4}>
                  {navItem.children && <Flex key={navItem.label} alignItems="stretch" flexDirection={'column'} {...navItem}>
                      {navItem.children.map(child => <Box key={child.label} py={0} fontSize={'sm'}>
                          <Link color="white" href={child.href}>
                            {child.label}
                          </Link>
                        </Box>)}
                    </Flex>}
                </Box>
              </Accordion.ItemContent>
            </Accordion.Item>
          </Accordion.Root>
        </Box>)}
    </nav>;
}