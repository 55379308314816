export function setCookie(name: string, value: any, options: any = {}) {
  document.cookie = `${name}=${encodeURIComponent(value)}${Object.keys(
    options
  ).reduce((acc, key) => {
    return (
      acc +
      `;${key.replace(/([A-Z])/g, ($1) => '-' + $1.toLowerCase())}=${
        options[key]
      }`
    );
  }, '')}`;
}
