'use client';

import { Box } from '@/styled-system/jsx';
import { container } from '@/styled-system/patterns';
import Sticky from '@/components/ui/sticky/Sticky';
import Nav from '@/components/ui/header/Nav';
export default function Header() {
  return <Sticky zIndex={10}>
      <Box bg={'white !important'} borderBottomColor={'gray.200'} borderBottomStyle={'solid'} borderBottomWidth={1} id="header">
        <header className={container({
        maxW: '7xl',
        px: {
          base: 0,
          lg: 4
        },
        position: 'static'
      })}>
          <Nav />
        </header>
      </Box>
    </Sticky>;
}