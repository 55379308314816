import { css } from '@/styled-system/css';

export const styledMobileNav = css.raw({
  '& .ais-SearchBox-input': {
    background: 'white',
    pr: '60px',
  },
  '& .ais-SearchBox-input::-webkit-search-cancel-button': {
    position: 'absolute',
    right: '33px',
    top: 0,
  },
  '& .ais-SearchBox-input::placeholder': {
    fontStyle: 'italic',
  },
  '& .mobile-nav .btn-login': {
    padding: 0,
    textDecoration: 'underline',
  },
});
