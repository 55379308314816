import Image from '@/components/image/Image';
import { Text } from '@/components/ui/typography/Text';
import { Box, Flex } from '@/styled-system/jsx';
import { Link } from '@/components/ui/link/Link';
import { IoMdClose } from 'react-icons/io';
import { RxHamburgerMenu } from 'react-icons/rx';
import { DesktopNav } from '@/components/ui/header/DesktopNav';
import { MobileNav } from '@/components/ui/header/MobileNav';
import HeaderActions from '@/components/ui/header/HeaderActions';
import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { box } from '@/styled-system/patterns';
export default function Nav({
  onNavToggle = null
}: {
  onNavToggle?: (open: boolean) => void;
}) {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(!isOpen);
  const eleRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      onToggle();
    }
  }, [pathname]);
  useEffect(() => {
    if (onNavToggle) {
      onNavToggle(isOpen);
    }
  }, [isOpen]);
  useOnClickOutside(eleRef, () => {
    if (isOpen) {
      onToggle();
    }
  });
  return <nav id="navigation" className={box()} ref={eleRef}>
      <Flex alignItems={'center'} px={{
      base: 4,
      lg: 0
    }}>
        <Flex display={{
        base: 'flex',
        lg: 'none'
      }} flex={{
        base: 1,
        lg: 'auto'
      }}
      // px={4}
      onClick={onToggle}>
          {isOpen ? <IoMdClose size={20} /> : <Box color={'brand.periwinkle.500'}>
              <RxHamburgerMenu size={30} />
              <Text color={'brand.periwinkle.400'} fontSize="10px" fontWeight={500} textTransform={'uppercase'}>
                Menu
              </Text>
            </Box>}
        </Flex>

        <Flex alignItems={'center'} flex={{
        base: 1
      }} justify={{
        base: 'center',
        lg: 'start'
      }} height={{
        base: '65px',
        lg: 'auto'
      }}>
          <Link href={'/welcome/'}>
            <Image alt="Cake's logo" height={40} quality={25} src="https://joincake.imgix.net/CakeLogo_Periwinkle_LogoMark_NoClearspace.svg" width={139} />
          </Link>
          <Flex display={{
          base: 'none',
          lg: 'flex'
        }} style={{
          marginLeft: 34
        }}>
            <DesktopNav />
          </Flex>
        </Flex>

        <HeaderActions onToggle={onToggle} />
      </Flex>
      <Box display={isOpen ? 'block' : 'none'}>
        <MobileNav />
      </Box>
    </nav>;
}