'use client';

import { ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Box } from '@/styled-system/jsx';
import { useEffect, useState } from 'react';
interface ModalProps {
  header?: string | ReactNode;
  children: string | ReactNode;
  footer?: string | ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}
export default function Modal({
  header,
  children,
  footer,
  isOpen = false,
  onClose = () => {}
}: ModalProps) {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  if (!open) {
    return null;
  }
  return <Box alignItems={'center'} backgroundColor={'rgba(0, 0, 0, .5)'} className="modal-overlay" left={0} display={'flex'} justifyContent={'center'} paddingX=".5rem" position={'fixed'} height={'100vh'} top={0} width={'100vw'} zIndex={1050}>
      <Box backgroundColor={'#fff'} borderRadius={'.5rem'} maxWidth={700} width="100%">
        {header && <Box alignItems="center" display="flex" justifyContent="between" paddingX={4} borderBottom={1}>
            {header}

            <Box cursor={'pointer'} marginLeft={'auto'}>
              <IoMdClose size={20} onClick={handleClose} />
            </Box>
          </Box>}

        <Box padding={'1rem'} position={'relative'}>
          {children}
        </Box>

        {footer && <Box alignItems="center" borderTop={'1px solid #dee2e6'} display="flex" justifyContent="end" padding={4}>
            {footer}
          </Box>}
      </Box>

      {/* <Box
        backgroundColor={'#fff'}
        borderRadius={'.5rem'}
        className="modal"
        margin={'auto'}
       >
        <Box className="modal-content" width="100%">
          {header && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="between"
              paddingX={4}
              borderBottom={1}
            >
              {header}
               <Box cursor={'pointer'} marginLeft={'auto'}>
                <IoMdClose size={20} onClick={handleClose} />
              </Box>
            </Box>
          )}
           <Box padding={'1rem'} position={'relative'}>
            {children}
          </Box>
           {footer && (
            <Box
              alignItems="center"
              borderTop={'1px solid #dee2e6'}
              display="flex"
              justifyContent="end"
              padding={4}
            >
              {footer}
            </Box>
          )}
        </Box>
       </Box> */}
    </Box>;
}