import { Box, Stack } from '@/styled-system/jsx';
import { Button } from '@/components/ui/button/Button';
import Guest from '@/components/user/Guest';
import { BsSearch } from 'react-icons/bs';
export default function GuestActions({
  onSearchToggle = null,
  showLogin = true,
  showSearch = false
}: {
  onSearchToggle?: () => void;
  showLogin?: boolean;
  showSearch?: boolean;
}) {
  return <Guest>
      <Stack direction={['column', 'row']} gap={{
      sm: 5,
      lg: 1
    }}>
        {showSearch && <Box color={'brand.periwinkle.500'}>
            <BsSearch aria-label={'Toggle Search'} onClick={onSearchToggle} size={20} />
          </Box>}

        {showLogin && <>
            <Button as="a" href={'/account/login/'} display={{
          base: 'none',
          lg: 'inline-block'
        }} textDecoration={{
          lg: 'underline'
        }} visual={'ghost'} w={{
          base: '100%',
          sm: 'auto'
        }}>
              Log in
            </Button>

            <Button as="a" display={{
          base: 'inline-block',
          lg: 'none'
        }} href={'/account/login/'} textDecoration={{
          lg: 'underline'
        }} visual={'primary'} w={{
          base: '100%',
          sm: 'auto'
        }}>
              Log in
            </Button>
          </>}

        {/* {showStartHere && (
          <Button as={'a'} href={'/split/top-bar-split/'} visual={'primary'}>
            Get help now
          </Button>
         )} */}
      </Stack>
    </Guest>;
}