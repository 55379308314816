import { ReactNode } from 'react';
import { Box } from '@/styled-system/jsx';
import { Heading } from '@/components/ui/typography/Heading';
import { cva } from '@/styled-system/css';
const alert = cva({
  base: {
    py: 3
  },
  variants: {
    visual: {
      danger: {
        bg: 'red.100'
      },
      info: {
        bg: '#264b68',
        color: 'white'
      },
      warning: {
        bg: 'orange.100'
      },
      secondary: {
        bg: 'gray.100',
        color: 'black'
      },
      secondaryRounded: {
        bg: 'gray.100',
        borderRadius: 5,
        color: 'black'
      }
    }
  }
});
export function Alert({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  return <Box className={alert({
    visual: 'info',
    ...props
  })}>{children}</Box>;
}
export function AlertTitle({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  return <Heading as="h6" fontFamily="body" fontWeight={500} {...props}>
      {children}
    </Heading>;
}
export function AlertDescription({
  children,
  ...props
}: {
  children: ReactNode;
  [props: string]: any;
}) {
  return <Box {...props}>{children}</Box>;
}