import { ListItem, UnorderedList } from '@/components/ui/list/List';
import { Link } from '@/components/ui/link/Link';
import { Text } from '@/components/ui/typography/Text';
import { Box } from '@/styled-system/jsx';
import { INavItem } from '@/types/navitem';

// note: parameters included {label, href, subLabel}
export const DesktopSubNav = ({
  label,
  labelMobileOnly,
  children
}: INavItem) => {
  return <Box className="topic-group" role={'group'} display={'block'}>
      <Text as={'h6'} color={'black'} fontWeight={'600'} fontSize={'sm'} display={labelMobileOnly ? {
      lg: 'none'
    } : 'block'}>
        {label}
      </Text>

      {children && <UnorderedList>
          {children.map(navItem => <ListItem key={navItem.label} fontSize={'sm'} fontWeight={400}>
              <Link color={'black'} href={`${navItem.href}`} _hover={{
          textDecoration: 'underline'
        }}>
                {navItem.label}
              </Link>
            </ListItem>)}
        </UnorderedList>}
    </Box>;
};