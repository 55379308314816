import { Box, Stack } from '@/styled-system/jsx';
import GuestActions from '@/components/ui/header/GuestActions';
import UserActions from '@/components/ui/header/UserActions';
export default function HeaderActions({
  onToggle = null
}: {
  onToggle: () => void;
}) {
  return <Stack flex={{
    base: 1,
    lg: 0
  }} justify={'flex-end'} direction={'row'} gap={3}>
      {/* Mobile */}
      <Box display={{
      lg: 'none'
    }}>
        <GuestActions showLogin={false} showSearch={true}
      // showStartHere={false}
      onSearchToggle={onToggle} />

        <UserActions showDashboard={false} showLogout={false} showSearch={true} onSearchToggle={onToggle} />
      </Box>

      {/* Desktop */}
      <Box display={{
      base: 'none',
      lg: 'block'
    }}>
        <GuestActions showLogin={true} showSearch={false}
      // showStartHere={true}
      onSearchToggle={onToggle} />

        <UserActions showDashboard={true} showLogout={true} showSearch={false} onSearchToggle={onToggle} />
      </Box>
    </Stack>;
}