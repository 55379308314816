import { INavItem } from '@/types/navitem';

export const NAV_ITEMS_FOOTER: Array<INavItem> = [
  {
    label: 'About',
    children: [
      {
        label: 'About Cake',
        href: '/who-is-cake/',
      },
      {
        label: 'FAQ',
        href: '/faq/',
      },
      {
        label: 'Press',
        href: '/press/',
      },
      {
        label: 'Careers',
        href: '/careers/',
      },
    ],
  },
  {
    label: 'Tools',
    children: [
      {
        label: 'Memorial pages',
        href: '/online-memorial-pages/',
      },
      {
        label: 'Will maker',
        href: '/online-wills-welcome/',
      },
      {
        label: 'End-of-life wishes',
        href: '/profile/',
      },
      {
        label: 'Post loss checklist',
        href: '/post-loss-checklist-start-here/',
      },
    ],
  },
  {
    label: 'Resources',
    href: '',
    children: [
      {
        label: 'Blog & Knowledge Base',
        href: '/blog/',
      },
      {
        label: 'Advance directive forms by state',
        href: '/advance-directives/',
      },
      {
        label: 'COVID-19 Advance Directive forms',
        href: '/l/form/ariadne-covid/',
      },
      {
        label: 'Trusted decision maker form',
        href: '/trusted-decision-maker/',
      },
      {
        label: 'Funeral home directory',
        href: '/funeral-directory/',
      },
    ],
  },
  {
    label: 'Other',
    children: [
      {
        label: 'Terms of use',
        href: '/terms/',
      },
      {
        label: 'Privacy policy',
        href: '/privacy-policy/',
      },
      {
        label: 'Editorial process',
        href: '/editorial-process/',
      },
      {
        label: 'Affiliate disclosure',
        href: '/affiliate-disclosure/',
      },
      {
        label: 'Code of conduct',
        href: '/code-of-conduct/',
      },
      {
        label: 'Account help',
        href: '/account-help/',
      },
    ],
  },
];
