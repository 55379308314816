'use client';

import { Alert, AlertDescription } from '@/components/ui/alert/Alert';
import { Button } from '@/components/ui/button/Button';
import { Text } from '@/components/ui/typography/Text';
import { Box, Container, Flex } from '@/styled-system/jsx';
import { Link } from '@/components/ui/link/Link';
import { useState } from 'react';
import { setCookie } from '@/utils/cookie/setCookie';
export default function CookieConsentAlert({
  cookieName,
  cookieValue
}: {
  cookieName: string;
  cookieValue: string;
}) {
  const [show, setShow] = useState(true);
  const updateCookie = () => {
    setCookie(cookieName, cookieValue, {
      expires: 365,
      path: '/',
      sameSite: 'none',
      secure: true
    });
    setShow(false);
  };
  return <Box display={show ? 'block' : 'none'}>
      <Alert px={{
      base: 0,
      md: 4
    }} visual={'info'}>
        <Container maxW={'7xl'}>
          <Flex alignItems="center">
            <AlertDescription fontSize={{
            base: 'xs',
            md: 'md'
          }}>
              <Text as="strong">
                We use cookies to improve your experience.
              </Text>{' '}
              <Text as="span">By accepting, you agree to our</Text>{' '}
              <Link color="white" href="/privacy-policy" target="_blank" textDecoration={'underline'}>
                Cookie Policy
              </Link>
              .{' '}
              <Link color="white" href="https://support.google.com/accounts/answer/61416" target="_blank" textDecoration={'underline'}>
                Learn about opting out
              </Link>
              .
            </AlertDescription>
            <Box ml={{
            base: 0,
            md: 4
          }} mt={{
            base: 3,
            md: 0
          }}>
              <Button onClick={() => {
              updateCookie();
            }} visual={'primary'} w={{
              base: '100%',
              md: 'auto'
            }}>
                Accept
              </Button>
            </Box>
          </Flex>
        </Container>
      </Alert>
    </Box>;
}