export const EVENTS = [
  'FSCommand',
  'onAbort',
  'onActivate',
  'onAfterPrint',
  'onAfterUpdate',
  'onBeforeActivate',
  'onBeforeCopy',
  'onBeforeCut',
  'onBeforeDeactivate',
  'onBeforeEditFocus',
  'onBeforePaste',
  'onBeforePrint',
  'onBeforeUnload',
  'onBeforeUpdate',
  'onBegin',
  'onBlur',
  'onBounce',
  'onCellChange',
  'onChange',
  'onClick',
  'onContextMenu',
  'onControlSelect',
  'onCopy',
  'onCut',
  'onDataAvailable',
  'onDataSetChanged',
  'onDataSetComplete',
  'onDblClick',
  'onDeactivate',
  'onDrag',
  'onDragEnd',
  'onDragLeave',
  'onDragEnter',
  'onDragOver',
  'onDragDrop',
  'onDragStart',
  'onDrop',
  'onEnd',
  'onError',
  'onErrorUpdate',
  'onFilterChange',
  'onFinish',
  'onFocus',
  'onFocusIn',
  'onFocusOut',
  'onHashChange',
  'onHelp',
  'onInput',
  'onKeyDown',
  'onKeyPress',
  'onKeyUp',
  'onLayoutComplete',
  'onLoad',
  'onLoseCapture',
  'onMediaComplete',
  'onMediaError',
  'onMessage',
  'onMouseDown',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseOut',
  'onMouseOver',
  'onMouseUp',
  'onMouseWheel',
  'onMove',
  'onMoveEnd',
  'onMoveStart',
  'onOffline',
  'onOnline',
  'onOutOfSync',
  'onPaste',
  'onPause',
  'onPopState',
  'onProgress',
  'onPropertyChange',
  'onReadyStateChange',
  'onRedo',
  'onRepeat',
  'onReset',
  'onResize',
  'onResizeEnd',
  'onResizeStart',
  'onResume',
  'onReverse',
  'onRowsEnter',
  'onRowExit',
  'onRowDelete',
  'onRowInserted',
  'onScroll',
  'onSeek',
  'onSelect',
  'onSelectionChange',
  'onSelectStart',
  'onStart',
  'onStop',
  'onStorage',
  'onSyncRestored',
  'onSubmit',
  'onTimeError',
  'onTrackChange',
  'onUndo',
  'onUnload',
  'onURLFlip',
  'seekSegmentTime',
];
