import { cva } from '@/styled-system/css';

const primary = {
  background: 'gold.500',
  borderRadius: '25px',
  color: '#293334',
  display: 'inline-block',
  fontWeight: 500,
  lineHeight: 1,
  minWidth: '110px',
  padding: '12px 16px',
  textAlign: 'center',
  textDecoration: 'none',
  transition: 'all .1s ease-out',
  whiteSpace: 'nowrap',
  _visited: {
    color: '#293334',
  },
  _hover: {
    opacity: 0.75,
  },
};

const ghost = {
  color: '#293334',
  display: 'inline-block',
  fontWeight: 500,
  lineHeight: 1,
  padding: '12px 16px',
  textAlign: 'center',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  _hover: {
    color: 'link',
    textDecoration: 'none',
  },
  _visited: {
    color: '#293334',
  },
};

export const styledButton = cva({
  base: {
    cursor: 'pointer',
    display: 'flex',
  },
  variants: {
    visual: {
      ghost,
      ghostFlat: {
        ...ghost,
        px: 0,
      },
      outline: {
        ...primary,
        background: '#fff',
        borderColor: 'blue.700',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: 'blue.700',
        padding: '8px 16px',
        _visited: {
          color: 'blue.700',
        },
        _hover: {
          opacity: 1,
          textDecoration: 'underline',
        },
        '& a': {
          color: 'blue.700',
          _hover: {
            opacity: 1,
            textDecoration: 'underline',
          },
          _visited: {
            color: 'blue.700',
          },
        },
      },
      primary,
      primaryWhite: {
        ...primary,
        background: '#fff',
      },
      primaryV2: {
        ...primary,
        borderRadius: '5px',
      },
      secondary: {
        ...primary,
        background: '#7bcdc8',
      },
      gray: {
        ...primary,
        background: 'gray.300',
      },
    },
    size: {
      sm: { fontSize: '12px' },
      md: { fontSize: '14px' },
      lg: { fontSize: '16px' },
    },
    width: {
      full: { width: '100% ' },
    },
  },
});
