import { css } from '@/styled-system/css';
import { ReactNode } from 'react';
import { Heading } from '@/components/ui/typography/Heading';
import { getPropsFromElement } from '@/utils/parser/htmlAttributes';
import { omit } from '@/utils/array/omit';
export function Text({
  as = 'p',
  children,
  ...props
}: {
  as?: string;
  children: ReactNode;
  [props: string]: any;
}) {
  const attrs = omit(getPropsFromElement(as, props), 'className');
  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(as)) {
    return <Heading as={as} fontFamily={'body'} {...props}>
        {children}
      </Heading>;
  }
  if (as === 'p') {
    return <p className={css(props)} {...attrs}>
        {children}
      </p>;
  }
  if (as === 'span') {
    return <span className={css(props)} {...attrs}>
        {children}
      </span>;
  }
  if (as === 'strong' || as === 'b') {
    return <strong className={css(props)} {...attrs}>
        {children}
      </strong>;
  }
  if (as === 'em' || as === 'i') {
    return <em className={css(props)} {...attrs}>
        {children}
      </em>;
  }
  if (as === 'mark') {
    return <mark className={css(props)} {...attrs}>
        {children}
      </mark>;
  }
  if (as === 'small') {
    return <small className={css(props)} {...attrs}>
        {children}
      </small>;
  }
  if (as === 'del') {
    return <del className={css(props)} {...attrs}>
        {children}
      </del>;
  }
  if (as === 'ins') {
    return <ins className={css(props)} {...attrs}>
        {children}
      </ins>;
  }
  if (as === 'sub') {
    return <sub className={css(props)} {...props}>
        {children}
      </sub>;
  }
  if (as === 'sup') {
    return <sup className={css(props)} {...props}>
        {children}
      </sup>;
  }
  if (as === 'blockquote') {
    return <blockquote className={css(props)} {...props}>
        {children}
      </blockquote>;
  }
  if (as === 'code') {
    return <code className={css(props)} {...props}>
        {children}
      </code>;
  }
  if (as === 'pre') {
    return <pre className={css(props)} {...props}>
        {children}
      </pre>;
  }
  if (as === 'time') {
    return <time className={css(props)} {...props}>
        {children}
      </time>;
  }
  if (as === 'q') {
    return <q className={css(props)} {...attrs}>
        {children}
      </q>;
  }
  if (as === 'label') {
    return <label className={css(props)} {...attrs}>
        {children}
      </label>;
  }
  if (as === 'div') {
    return <div className={css(props)} {...attrs}>
        {children}
      </div>;
  }
}