import { Box, Flex } from '@/styled-system/jsx';
import { NAV_ITEMS_HEADER_MOBILE } from '@/data/navHeader';
import { MobileNavItem } from '@/components/ui/header/MobileNavItem';
import { ToolsSection } from '@/components/ui/header/ToolsSection';
import { BsSearch } from 'react-icons/bs';
import { styledMobileNav } from '@/components/ui/header/MobileNav.styles';
import GuestActions from '@/components/ui/header/GuestActions';
import UserActions from '@/components/ui/header/UserActions';
import dynamic from 'next/dynamic';
import { css } from '@/styled-system/css';
const BlogQuickSearch = dynamic(() => import('@/components/blog/BlogQuickSearch'), {
  ssr: false
});
export const MobileNav = () => {
  return <Box className={css(styledMobileNav)}>
      <Box bg={'beige.500'} className="mobile-nav" h={'min(calc(100vh - 50px), 640px)'} p={4} display={{
      lg: 'none'
    }}>
        <Flex flexDir={'column'} h={'100%'}>
          <BlogQuickSearch searchBox={{
          submitIconComponent: () => {
            return <Box color={'brand.periwinkle.500'}>
                    <BsSearch size={16} style={{
                position: 'relative',
                top: 10
              }} />
                  </Box>;
          }
        }} />

          <Box flex={'1 1 auto'} minH={0} overflowY={'auto'}>
            <Box mt={4}>
              {NAV_ITEMS_HEADER_MOBILE.map(navItem => <MobileNavItem key={navItem.label} {...navItem} />)}
            </Box>

            <Box mt={4}>
              <ToolsSection />
            </Box>
          </Box>

          <Box>
            <GuestActions
          // showStartHere={false}
          />
            <UserActions />
          </Box>
        </Flex>
      </Box>
    </Box>;
};