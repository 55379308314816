'use client';

import { Link } from '@/components/ui/link/Link';
import { Box, Divider, Flex } from '@/styled-system/jsx';
import { NAV_ITEMS_HEADER_DESKTOP } from '@/data/navHeader';
import { styledDesktopNav } from '@/components/ui/header/DesktopNav.styles';
import NavPopover from '@/components/ui/header/NavPopover';
import { INavItem } from '@/types/navitem';
import { css } from '@/styled-system/css';
const linkHoverTextColor = 'gray.800';
export const DesktopNav = () => {
  const primary = NAV_ITEMS_HEADER_DESKTOP.filter(item => !item.secondary);
  const secondary = NAV_ITEMS_HEADER_DESKTOP.filter(item => item.secondary);
  return <Box className={css(styledDesktopNav)}>
      <Flex>
        <Flex alignItems={'center'} height={65}>
          {primary.map((navItem: INavItem) => navItem.children ? <NavPopover key={navItem.label} mr={'13px'} navItem={navItem} /> : <SecondaryMenu key={navItem.label} navItem={navItem} />)}

          <Divider bg={'#a5a5a5'} h={'39%'} mr={'14px'} opacity={1} orientation="vertical" w={'2px'} />

          {secondary.map((navItem: INavItem, idx: number) => navItem.children ? <NavPopover key={navItem.label} mr={'13px'} navItem={navItem} /> : <SecondaryMenu key={navItem.label} mr={idx === secondary.length - 1 ? 0 : '13px'} navItem={navItem} />)}
        </Flex>
      </Flex>
    </Box>;
};
function SecondaryMenu({
  navItem,
  mr = '13px'
}: {
  navItem: INavItem;
  mr?: number | string;
}) {
  return <Flex alignItems={'center'} fontSize={'md'} fontWeight={500} h={65} key={navItem.label} mr={mr} style={navItem.style}>
      <Box alignItems="center" borderBottomColor={'transparent'} borderBottomStyle={'solid'} borderBottomWidth={8} display={'flex'} h={65} _hover={{
      borderColor: '#b5e2df',
      color: linkHoverTextColor
    }}>
        <Link alignItems={'center'} color={'black'} display={'flex'} height="100%" href={navItem.href} style={{
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 7,
        textDecoration: navItem.underline ? 'underline' : 'none'
      }}>
          {navItem.label}
        </Link>
      </Box>
    </Flex>;
}