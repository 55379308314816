import { Box } from '@/styled-system/jsx';
import { ReactNode } from 'react';
import { useSticky } from '@/hooks/useSticky';
export default function Sticky({
  children,
  zIndex = 2
}: {
  children: ReactNode | (({
    sticky
  }: {
    sticky: boolean;
  }) => ReactNode);
  zIndex?: number | string;
}) {
  const [sidebarRef, sticky] = useSticky<HTMLDivElement>();
  return <Box ref={sidebarRef} position={sticky ? 'sticky' : 'relative'} top={0} transition={'padding .1s ease-in'} zIndex={zIndex}>
      {typeof children === 'function' ? children({
      sticky
    }) : children}
    </Box>;
}