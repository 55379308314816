import { INavItem } from '@/types/navitem';

export const TOOLS_LIST: Array<INavItem> = [
  {
    label: 'Online memorials',
    subLabel: 'Free memorial pages',
    iconUrl: 'https://joincake.imgix.net/icon-online-memorial.svg',
    href: 'https://www.afterall.com/obituaries/?utm_source=joincake&utm_medium=affiliate&utm_campaign=ongoing&utm_content=navigation',
  },
  {
    label: 'Post Loss Checklist',
    subLabel: 'Your guide after loss',
    iconUrl: 'https://joincake.imgix.net/icon-plc.svg',
    href: '/post-loss-checklist-start-here/',
  },
  {
    label: 'Will Maker',
    subLabel: 'Create your legal will in minutes',
    iconUrl: 'https://joincake.imgix.net/icon-will.svg',
    href: '/online-wills-welcome/',
  },
  {
    label: 'End-of-life Wishes',
    subLabel: 'Create your end-of-life plan',
    iconUrl: 'https://joincake.imgix.net/icon-planning-tool.svg',
    href: '/profile/',
  },
  {
    label: 'Forum',
    subLabel: 'Advice from the community',
    iconUrl: 'https://joincake.imgix.net/icon-planning-tool.svg',
    href: '/forum/',
  },
  {
    label: 'Advance Directive Forms',
    subLabel: "Find your state's form",
    iconUrl: 'https://joincake.imgix.net/icon-pulse.svg',
    href: '/advance-directives/',
  },
];
