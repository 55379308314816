export function getCookie(name: string) {
  const cookies = document.cookie
    .split(';')
    .reduce((acc: any, cookieString: string) => {
      const [key, value] = cookieString.split('=').map((s) => s.trim());
      if (key && value) {
        acc[key] = decodeURIComponent(value);
      }
      return acc;
    }, {});
  return name ? cookies[name] || '' : cookies;
}
