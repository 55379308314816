'use client';

import Modal from '@/components/ui/modal/Modal';
import { useEffect, useState } from 'react';
import { Box } from '@/styled-system/jsx';
import { Text } from '@/components/ui/typography/Text';
import { Button } from '@/components/ui/button/Button';
const contentMap = {
  funeralHome: 'We are happy to introduce you to our funeral home partners at:',
  obituaries: 'We are happy to introduce you to our free obituary partner:',
  preplanning: 'We are happy to introduce you to our funeral preplanning partner:',
  shopping: 'We are happy to introduce you to our shopping partner:'
};
export default function TransitionModal() {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(contentMap.funeralHome);
  const [url, setUrl] = useState('');
  const handleLinkClick = (e: any) => {
    e.preventDefault();
    const anchor = e.target.closest('a');
    const href = anchor.getAttribute('href');
    let modalContent = contentMap.funeralHome;
    if (href.includes('funeral-planning')) {
      modalContent = contentMap.preplanning;
    } else if (href.includes('store')) {
      modalContent = contentMap.shopping;
    } else if (href.includes('obituaries')) {
      modalContent = contentMap.obituaries;
    }
    setContent(modalContent);
    setUrl(href);
    setShow(true);
  };
  const handleContentLinkClick = (e: any) => {
    const anchor = e.target.closest('a');
    const target = anchor.getAttribute('target');

    // If target is not set, force opening in new window
    if (!target) {
      e.preventDefault();
      const href = anchor.getAttribute('href');
      window.open(href);
    }
  };
  const goToUrl = () => {
    if (url) {
      window.open(url);
      setShow(false);
    }
  };
  useEffect(() => {
    const linkSelector = 'a[href*="afterall.com"]';
    const contentLinkSelector = `#main ${linkSelector}:not(.modal-link)`;
    const modalLinkSelector = `#header ${linkSelector}, #footer ${linkSelector}, .banner ${linkSelector}, ${linkSelector}.modal-link`;
    document.querySelectorAll(modalLinkSelector).forEach((ele: EventTarget) => ele.addEventListener('click', handleLinkClick));
    document.querySelectorAll(contentLinkSelector).forEach((ele: EventTarget) => ele.addEventListener('click', handleContentLinkClick));
    return () => {
      document.querySelectorAll(modalLinkSelector).forEach((ele: EventTarget) => ele.removeEventListener('click', handleLinkClick));
      document.querySelectorAll(contentLinkSelector).forEach((ele: EventTarget) => ele.removeEventListener('click', handleContentLinkClick));
    };
  }, []);
  return <Modal footer={<Box display="flex" gap="8px">
          <Button visual="secondary" onClick={() => setShow(false)}>
            Stay on Cake
          </Button>
          <Button visual="primary" onClick={goToUrl}>
            Go to Afterall
          </Button>
        </Box>} isOpen={show}>
      <Box textAlign="center" paddingY={'3rem'}>
        <Text className="content" fontSize={18} marginBottom="1rem">
          {content}
        </Text>
        <Box color="hsl(16, 85%, 74%)" className="afterall-logo afterall-primary" display={'flex'} justifyContent={'center'}>
          <svg xmlns="http://www.w3.org/2000/svg" focusable="false" fill="none" width="128" height="29" viewBox="0 0 124 28">
            <path fill="currentColor" d="M105.62.28h-5.04v27.27h5.04V.28ZM92.64 24.46l-.23.31A8.3 8.3 0 0 1 85.57 28c-2.27 0-3.98-.7-5.1-2.08a6.5 6.5 0 0 1-1.3-4.02c0-5.82 4.84-6.39 9.52-6.95l1.94-.24c1.2-.18 2-.85 1.93-1.65-.07-.9-1.3-1.92-4.54-1.92-7.47 0-12.37 3.72-12.37 10.75v5.65H70.6V7.8h4.78v3.99l.23-.24c3.06-3.03 6.86-4.48 12.1-4.48 6.67 0 9.9 2.99 9.9 9.12v11.37h-4.94v-3.1h-.03Zm-.26-6.84c-.8.25-1.77.43-2.8.61-2.76.48-5.87 1.11-5.87 3.36 0 1.53 1.05 2.19 2.8 2.19.26 0 .53-.02.8-.06 2.54-.43 5.24-2.86 5.24-5.98v-.18l-.17.06ZM113.64.28h-5.04v27.27h5.04V.28ZM59.06 28c-2.64 0-5-.83-7.03-2.46C49.97 23.88 49 21.41 49 17.8c0-3.44.92-6.11 2.75-7.95 1.82-1.84 4.21-2.77 7.13-2.77 1.73 0 3.3.31 4.7.93A8.13 8.13 0 0 1 67 10.94a9.8 9.8 0 0 1 1.58 4.12c.14.9.2 2.2.17 3.86H54.04v.14c.08 1.98.75 3.26 2.17 4.13.83.52 1.83.78 2.99.78 1.23 0 2.24-.32 3.02-.95.4-.34.77-.8 1.08-1.36h5.16a6.67 6.67 0 0 1-1.9 3.44c-1.77 1.92-4.3 2.9-7.5 2.9Zm-.2-16.83c-1.38 0-2.47.4-3.24 1.2a5.33 5.33 0 0 0-1.35 2.67l-.03.16h9.25l-.02-.15a4.35 4.35 0 0 0-1.4-2.75 4.68 4.68 0 0 0-3.2-1.13ZM47.03 27.72c-2.54 0-4.39-.72-5.51-2.15a6.26 6.26 0 0 1-1.2-3.86V11.7h-5.57v15.84h-5.04V6.66C29.71 2.67 32.45 0 36.5 0c.54 0 1.03.05 1.58.15v3.93a6 6 0 0 0-.54-.03c-1.04 0-2.8.32-2.8 2.46v1.3h5.57V3.73h5.08v4.08h3.28v3.9H45.4V21.39c0 .84.17 1.4.54 1.79.39.4.98.58 1.88.58.25 0 .55 0 .86-.04v3.92c-.57.05-1.14.08-1.65.08ZM21.7 27.54v-4.8H10.1l-3.8 4.8H0L21.52.26h5.2v27.28H21.7Zm-8.53-8.71h8.53V8l-8.53 10.83ZM117.18 1.03h-1.11V.28h3.09v.75h-1.12v3.15h-.86V1.03ZM119.6.28h1.29l.9 2.54.91-2.54h1.3v3.9h-.86v-3h-.01l-1.02 3h-.66l-.99-3v3h-.87V.28Z"></path>
          </svg>
        </Box>
      </Box>
    </Modal>;
}